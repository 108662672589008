/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-plusplus */
// Copied as-is from https://github.com/mariohmol/js-brasil
//
// Last Commit: 52fa1853672e3a25dc543b0cf8ac3adbb67951a9
//
// Once we resolve the issues with building typescript js-brasil we can use it
// directly from Upstream

export const modulo11 = (str: string, size: number, mod: number) => {
  let soma = 0;
  for (let i = 1; i <= size; i++) {
    soma += parseInt(str.substring(i - 1, i), 10) * (mod - i);
  }
  let resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  return resto;
};

/**
 * @param strCPF
 */
export const create_cpf = (strCPF: string) => {
  strCPF = strCPF.replace(/[^\d]+/g, '');
  if (strCPF === '00000000000') {
    return false;
  }

  const restos = [modulo11(strCPF, 9, 11), modulo11(strCPF, 10, 12)];

  return restos;
};

/**
 * @param cnpj
 */
export const create_cnpj = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') {
    return false;
  }

  if (cnpj.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  // Valida DVs
  let tamanho: number = cnpj.length - 2;
  let numeros: any = cnpj.substring(0, tamanho);
  let soma: any = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  const resultados = [0, 0];
  resultados[0] = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultados[1] = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultados;
};

/**
 * @param cnpj
 */
export const validate_cnpj = (cnpj: any) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');
  const tamanho = cnpj.length - 2;
  const digitos = cnpj.substring(tamanho);
  const resultados = create_cnpj(cnpj);
  if (resultados === false) {
    return false;
  }
  if (resultados[0] !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  if (resultados[1] !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }
  return true;
};

// http://www.receita.fazenda.gov.br/aplicacoes/atcta/cpf/funcoes.js
/**
 * @param strCPF
 */
export const validate_cpf = (strCPF: any) => {
  strCPF = strCPF.replace(/[^\d]+/g, '');
  if (strCPF.length !== 11) {
    return false;
  }
  const restos = create_cpf(strCPF);

  if (restos === false) {
    return false;
  }

  if (restos[0] !== parseInt(strCPF.substring(9, 10), 10)) {
    return false;
  }

  if (restos[1] !== parseInt(strCPF.substring(10, 11), 10)) {
    return false;
  }
  return true;
};

export const validateBr = {
  cnpj: validate_cnpj,
  cpf: validate_cpf,
};
