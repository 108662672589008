import { Field } from 'react-final-form';
import { InputLabelProps, SxProps, TextField as MUITextField } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import { ChangeEvent, FC } from 'react';

export interface TextFieldProps {
  name: string;
  fullWidth?: boolean;
  variant?: any;
  placeholder?: string;
  label?: string;
  initialValue?: any;
  multiline?: boolean;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  rows?: number;
  rowsMax?: number;
  value?: any;
  type?: string;
  required?: boolean;
  InputProps?: InputProps;
  InputLabelProps?: InputLabelProps;
  autoFocus?: boolean;
  sx?: SxProps;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onFocus?: (e: any) => void;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  initialValue,
  value,
  type,
  onFocus,
  onChange,
  ...fieldProps
}) => {
  return (
    <Field
      name={name}
      initialValue={initialValue}
      type={type}
      render={({ input, meta }) => (
        <MUITextField
          variant="outlined"
          {...input}
          {...fieldProps}
          value={value || input.value}
          error={Boolean(meta.touched && meta.error)}
          onFocus={onFocus}
          onChange={(e: any) => {
            input.onChange(e);
            if (onChange != null) {
              onChange(e);
            }
          }}
          helperText={
            meta.touched && meta.error
              ? meta.error
              : fieldProps.helperText
              ? fieldProps.helperText
              : null
          }
        />
      )}
    />
  );
};
