import { TextField } from '@mui/material';
import { Field } from 'react-final-form';
import { FC, useState, FunctionComponent } from 'react';
import { PhoneMaskField } from './phone-mask-field';

interface OwnProps {
  name: string;
  fullWidth?: boolean;
  variant?: any;
  placeholder?: string;
  label?: string;
  initialValue?: any;
  multiline?: boolean;
  disabled?: boolean;
  helperText?: string;
  validate?: (value: any) => any;
  rows?: number;
  rowsMax?: number;
  required?: boolean;
}

export const PhoneNumberField: FC<OwnProps> = ({ initialValue, name, validate, ...props }) => {
  const [phone, setPhone] = useState(
    initialValue != null ? initialValue.replace('+55', '') : undefined,
  );
  const handleChange = (inputChange: any) => (e: any) => {
    const val = e.target.value;
    setPhone(val);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    inputChange(val != null && val.length > 0 ? `+55${val.replace(/[^0-9]/gi, '')}` : '');
  };
  return (
    <Field
      name={name}
      type="tel"
      validate={validate}
      render={({ input, meta }) => {
        const error = meta.error || meta.submitError;
        const touched = meta.touched || meta.dirtySinceLastSubmit;
        return (
          <TextField
            {...input}
            {...props}
            onChange={handleChange(input.onChange)}
            value={phone || input.value.replace('+55', '')}
            InputProps={{ inputComponent: PhoneMaskField as FunctionComponent }}
            error={Boolean(touched && error)}
            helperText={touched && error ? error : props.helperText ? props.helperText : null}
          />
        );
      }}
    />
  );
};
