import {
  Button,
  ButtonProps,
  ButtonTypeMap,
  CircularProgress,
  ExtendButtonBase,
} from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Position = 'start' | 'middle' | 'end';

type IconPosition = Exclude<Position, 'middle'>;

export interface ButtonLoaderProps extends ButtonProps {
  ButtonEl?: ExtendButtonBase<ButtonTypeMap<{}, 'button'>>;
  loading?: boolean;
  label: string;
  onLoadingLabel?: string;
  loadingIconPosition?: Position;
  defaultIconPosition?: IconPosition;
  icon?: ReactNode;
}

interface GetButtonIconByPositionParams {
  defaultIconPosition?: IconPosition;
  loadingIconPosition?: Position;
  loading?: boolean;
  icon: ReactNode;
}

const getDefaultIconForIconPosition = (defaultIconPosition: IconPosition) =>
  defaultIconPosition === 'end' ? <ArrowForwardIcon /> : <ArrowBackIcon />;

const getIconButtonByPosition = ({
  defaultIconPosition,
  loadingIconPosition,
  loading,
  icon,
}: GetButtonIconByPositionParams) => {
  const positions = [...new Set([defaultIconPosition, loadingIconPosition])];

  const positionedIcon = positions.reduce((obj: Record<string, any>, position) => {
    if (position != null && position !== 'middle') {
      return {
        ...obj,
        [`${position}Icon`]:
          loadingIconPosition === position && loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : defaultIconPosition === position && !loading ? (
            icon || getDefaultIconForIconPosition(position)
          ) : null,
      };
    }

    return obj;
  }, {});

  return positionedIcon;
};

export const ButtonLoader: FC<ButtonLoaderProps> = ({
  ButtonEl = Button,
  loading,
  label,
  disabled,
  onLoadingLabel = 'Carregando...',
  loadingIconPosition = 'middle',
  defaultIconPosition,
  icon,
  ...props
}) => {
  const iconPositionProps = useMemo(
    () =>
      getIconButtonByPosition({
        icon,
        defaultIconPosition,
        loading,
        loadingIconPosition,
      }),
    [defaultIconPosition, icon, loading, loadingIconPosition],
  );
  return (
    <ButtonEl disabled={disabled || loading} {...iconPositionProps} {...props}>
      {loading ? (
        loadingIconPosition === 'middle' ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          onLoadingLabel
        )
      ) : (
        label
      )}
    </ButtonEl>
  );
};
