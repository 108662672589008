import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { CoffeeSpinner } from './coffee-spinner';

interface OwnProps {
  /** A flag that sets if the loading is shown */
  loading?: boolean;
  loadingMessage?: string;
  className?: string;
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
  &[data-show='1'] {
    display: flex;
  }
`;

export const ContainerLoader: FC<OwnProps> = ({
  children,
  loading = false,
  loadingMessage,
  className,
}) => (
  <Container className={className}>
    {children}
    <Loader data-show={loading ? 1 : 0}>
      <CoffeeSpinner />
      {loadingMessage && (
        <Box mt={2} textAlign="center">
          <Typography variant="subtitle2" data-e2e="cf-loading-message">
            {loadingMessage}
          </Typography>
        </Box>
      )}
    </Loader>
  </Container>
);

export default ContainerLoader;
