import { Form, FormRenderProps } from 'react-final-form';
import { validate } from '@web/common/validation/validation';
import { Grid } from '@mui/material';
import { GridProps, GridSpacing } from '@mui/material/Grid';
import { Mutator, ValidationErrors } from 'final-form';
import { FC } from 'react';

interface OwnProps {
  initialValues?: any;
  gridSpacing?: GridSpacing;
  gridProps?: GridProps;
  constraint?: Record<string, any> | ((values: Record<string, any>) => Record<string, any>) | null;
  render?: (props: FormRenderProps) => any;
  onSubmit: (values: any, form: any) => void;
  validateFn?: (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined;
  mutators?: {
    [key: string]: Mutator<any>;
  };
}

export const DefaultForm: FC<OwnProps> = ({
  initialValues,
  constraint,
  gridSpacing = 4,
  render,
  onSubmit,
  validateFn,
  children,
  mutators,
  gridProps,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={mutators}
      validate={
        validateFn != null
          ? validateFn
          : constraint != null
          ? (values: any) =>
              validate(values, typeof constraint === 'function' ? constraint(values) : constraint)
          : undefined
      }
      render={({ ...props }) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <Grid container spacing={gridSpacing} {...gridProps}>
            {render != null ? render(props as any) : null}
            {children}
          </Grid>
        </form>
      )}
    />
  );
};
