/* eslint-disable max-params */
import { formatters, validators, validate as validateFunc, ValidateOption } from 'validate.js';
import { validate_cpf, validate_cnpj } from '@web/common/validators/cpf_cnpj';
import { Iugu } from '@web/common/iugu';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import differenceInYears from 'date-fns/differenceInYears';

const errorMsgs: { [key: string]: string } = {
  presence: 'Campo obrigatório',
  email: 'Email inválido',
  phone: 'Número inválido',
  arrayPresence: 'Campo obrigatório',
  equality: 'Este campo não confere com o original',
  cpf_cnpj: 'CPF/CNPJ inválido',
  birthday: 'Data inválida. Formato esperado: DD/MM/AAAA',
  eighteen: 'Necessário ter pelo menos 18 anos',
  cardNumber: 'Número do Cartão inválido',
  cardExpire: 'Data de validade inválida',
  cardCVV: 'Código de verificação inválido',
  fullName: 'Nome completo inválido',
  length: 'Número de caracteres inválido',
};

formatters.custom = (errors: any[]) => {
  return errors.reduce((acc, error) => {
    if (!acc.hasOwnProperty(error.attribute)) {
      acc[error.attribute] = errorMsgs[error.validator];
    }
    return acc;
  }, {});
};

validators.phone = (value: any) => {
  return /(?=^(\+?5{2}\-?|0)\d{2}\-?\d{4}\-?\d{4}$)(^(\+?5{2}\-?|0)\d{2}\-?\d{4}\-?\d{4}$)|(^(\+?5{2}\-?|0)\d{2}\-?9\d{4}\-?\d{4}$)/.test(
    value,
  )
    ? null
    : 'Not a valid phone';
};

validators.cpf_cnpj = (value: any) => {
  return value != null && (validate_cpf(value) || validate_cnpj(value))
    ? null
    : 'Not a valid CPF/CNPJ';
};

validators.arrayPresence = (value: any) =>
  value != null && Array.isArray(value) && value.length > 0 ? null : 'Not a valid list';

validators.cardValid = (value: any) =>
  isAfter(parse(value, 'MM/yy', new Date()), new Date()) ? null : 'Not a valid date';

validators.birthday = (value: any) => {
  if (value == null || value.replace(/[^0-9]/gi, '').length < 8) {
    return 'Not a valid date';
  }
  const d = parse(value, 'dd/MM/yyyy', new Date());
  return isValid(d) && d.getFullYear() > 1800 ? null : 'Not a valid date';
};

validators.eighteen = (value: any) =>
  differenceInYears(new Date(), parse(value, 'dd/MM/yyyy', new Date())) >= 18
    ? null
    : 'You must be 18 years old or more';

validators.cardNumber = (
  value: any,
  _: any,
  __: string,
  ___: any,
  globalOptions: { iugu: Iugu },
) => {
  return (value != null &&
    globalOptions.iugu != null &&
    globalOptions.iugu.validateCreditCardNumber(value)) ||
    globalOptions.iugu == null
    ? null
    : 'Not a valid credit card number';
};

validators.cardExpire = (
  value: any,
  _: any,
  __: string,
  ___: any,
  globalOptions: { iugu: Iugu },
) => {
  return (value != null &&
    globalOptions.iugu != null &&
    globalOptions.iugu.validateExpirationString(value)) ||
    globalOptions.iugu == null
    ? null
    : 'Not a valid expiration';
};

validators.cardCVV = (
  value: any,
  _: any,
  __: string,
  attributes: any,
  globalOptions: { iugu: Iugu },
) => {
  return (value != null &&
    attributes?.number != null &&
    globalOptions.iugu != null &&
    globalOptions.iugu.validateCVV(attributes.number, value)) ||
    globalOptions.iugu == null
    ? null
    : 'Not a valid card verification value';
};

validators.fullName = (value: any) => {
  return value != null && typeof value === 'string' && value.split(' ').length > 1
    ? null
    : 'Not a valid full name';
};

export const validate = (attributes: any, constraints: any, options?: ValidateOption | any) => {
  const validation = validateFunc(attributes, constraints, { format: 'custom', ...options });
  return validation;
};

export const finalFormValidate = (constraints: any, options?: ValidateOption) => (values: any) =>
  validate(values, constraints, options);
