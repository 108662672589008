import { FC } from 'react';
import MaskedInput from 'react-text-mask';

interface OwnProps {
  /** A reference to the input inside the mask field */
  inputRef: any;
}

export const PhoneMaskField: FC<OwnProps> = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={inputRef}
    mask={[
      '(',
      /[0-9]/,
      /[0-9]/,
      ')',
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ]}
  />
);
